import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Tutorials from "../../static/helpcontent.json"


const Content = styled("div")`
  margin: 0 auto;
  margin-bottom: 240px;
  position:relative;
  top: 160px;
  padding-left: clamp(36px, 15vw, 250px);
  padding-right: clamp(36px, 15vw, 250px);
  section {
    margin-bottom: 60px;
    h1 {
      text-transform: uppercase;
    }
     h2 {
    font-weight: 700;
    margin-top: 24px;
    font-size: 20px;
    text-align: left;
    margin-bottom: 0;
  }
    p{
      margin-bottom: 24px;
    }
  }
  .videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }
  .videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 16px 32px 0 rgba(179, 86, 35, 0.39);
  } 
  hr {
    border: none;
    background-color: var(--sp-orange);
    height: 1px;
    margin-top: 60px;
  }
`

const TutorialsPage = () => {
  return (
    <Layout navForced>
      <SEO title={"Sheetplanner - Tutorials"}/>
      <Content>
        <h1>Tutorials</h1>
        <main>
          {Tutorials.videos.map(video => {
            return (
              <section key={'video_' + video.name}>
                <h2>{video.name}</h2>
                <p>{video.description}</p>
                <div className={'videoWrapper'}>

                <iframe
                  title={video.title}
                  width="1280"
                  height="720"
                  src={`https://www.youtube.com/embed/${video.id}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen />
                </div>
                <hr />
              </section>
            )
          })}
        </main>

        <Link to="/"><span className={"link"}>Go back to the homepage</span></Link>
      </Content>
    </Layout>
  )
}

export default TutorialsPage
